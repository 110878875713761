body {
  background-color: #ffffff;
  height: 100vh;
  font-feature-settings: "tnum" 1, "lnum" 1, "ss01" 1;
  -webkit-font-feature-settings: "tnum" 1, "lnum" 1, "ss01" 1;
  font-variant-numeric: tabular-nums lining-nums;
  letter-spacing: 0.2px;
}
.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.content {
  flex-grow: 2;
}

