@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap');

.customSubtitle1 {
    font-family: 'Raleway';
    font-size: 18px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 170%;
    color: #4A4A4A;
    
}
/* https://script.google.com/macros/s/AKfycbxhAzy1NaeaXthajGbyQnJHPwuFrANtPQQvkAAhu1lKseZEPXSKVQPBLjh8gDGUjg1l/exec */