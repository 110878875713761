.hero-text {
  font-size: 64px !important;
  font-weight: 700 !important;
  line-height: 130% !important;
  color: var(--white, #FFF);
  font-family: Montserrat;

}

.hero-text-large {
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 130% !important;
  color: var(--white, #FFF);
  font-family: Montserrat;
}

.carousel-container {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.carousel-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  animation: scroll-left 30s linear infinite;
}

h5 {
  color: #4A4A4A;
}

.hero-text {
  color: var(--white, #FFF);
  text-align: center;
  font-family: Montserrat;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

@keyframes scroll-left {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}
/* 
.circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 460.84px;
  height: 460.84px;
  background-color: rgba(92, 120, 90, 1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 30px 30px rgba(240, 244, 239, 0.8);
} */
