.header {
  position: relative; 
  ;
}

.header-logo {
  height: 45px;
}

.nav-links {
  display: flex;
  gap: 16px;
  margin-left: 60px;
}

.nav-link {
  text-decoration: none !important;
  text-transform: uppercase !important;
  /* color: #045B50 !important; */

}

.nav-link:hover,
.nav-link.active,.LoginMobile {
  color: rgba(92, 120, 90, 1) !important; 
}
.LoginMobile{
  display: none;
}
.mobile-nav {
  display: none;
}

.mobile-menu {
  display: none;
}

.mobile-menu.visible {
  display: flex;
  flex-direction: column; 
  gap: 18px; 
  margin-top: 30px;
  align-items: center;
  padding-bottom: 5px;
}

.mobile-menu .MuiListItem-root {
  padding: 0; 
}

.mobile-menu .MuiListItemText-root {
  flex-grow: 1;
}
.drop{
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  width: 100%;
  position: relative;
  margin-left: 1100px;
}
.dropMob{
  display: none;
}
@media (max-width: 960px) {
  .nav-links {
    display: none; 
    border-bottom:1px solid #549B79;
  }
  .Login{
    display: none; 
  }
  .nav-link {
    border-bottom:1px solid #549B79;
  }
  .LoginMobile{
    display: flex;
    border-bottom:1px solid #549B79;
    position: relative;
    bottom: 10px;
    color: #4A4A4A;
    text-decoration: none;
  }
  .mobile-nav {
    display: flex;
  }
  .drop{
    display: flex;
    margin-left: 0px;
  }
  /* .dropMob{
    display: flex ;
    justify-content: flex-end;
    padding: 10px;
    width: 100%;
    position: relative;
  } */
}

.MuiIconButton-root {
  color: #045B50 !important;
  position: relative;
  border-radius: 50%; 
  padding: 8px;
}

.MuiIconButton-root:hover,
.MuiIconButton-root:focus {
  color: #045B50;
  border-color: #004F40 !important;
  border: 2px dotted #004F40 !important;
}
