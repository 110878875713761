.custom-body-cell{
    padding: 16px 8px !important;
    text-align: center !important;
}
.custom-header-cell{
    padding: 32px 8px !important;
    text-align: center !important;
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: 700  !important;
    font-size: 16px !important;
    line-height: 130% !important;
    
    /* margin: 16px 0px !important; */
}
.MuiPopover-paper {
    padding: 8px; 
}

.MuiPopover-paper .MuiFormControlLabel-root {
    padding: 0px 12px; 
}




